@import url("https://fonts.googleapis.com/css2?family=Montserrat&family=Oswald:wght@200&display=swap");
:root {
  scrollbar-width: none;
  scroll-behavior: smooth;
  --background: hsl(206.3, 100%, 6.3%);
  --light-text: lightgrey;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Oswald", sans-serif;
}

h2 {
  font-size: 2rem;
  text-align: right;
  padding: 1rem 0;
  margin: 0;
}
.wide-bg {
  width: 100vw;
}

.dark {
  background-color: var(--background);
  color: var(--light-text);
}

body::-webkit-scrollbar {
  display: none; /* for Chrome, Safari, and Opera */
}

body {
  margin: 0;
  font-family: -apple-system, "Montserrat", BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  --nav-height: 8.5rem;
}
@media (min-width: 667px) {
  body {
    --nav-height: 5rem;
  }
}

#home,
section {
  scroll-margin-top: var(--nav-height);
}

section {
  margin: 0 auto;
  padding-left: 2rem;
  padding-right: 2rem;
  max-width: 960px;
  min-height: 100vh;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
