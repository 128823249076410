nav {
  background-color: var(--background);
  position: sticky;
  top: 0;
  width: 100%;
  height: var(--nav-height);
  display: flex;
  flex-direction: column-reverse;
  justify-content: space-between;
  align-items: center;
}
@media (min-width: 667px) {
  nav {
    flex-direction: row;
  }
}
nav > ul {
  display: flex;
  margin: 0;
  padding: 1rem;
}
nav > ul > li {
  list-style: none;
}
nav > ul > li > a {
  padding: 0.8rem;
  font-size: 1.2rem;
  color: var(--light-text);
  text-decoration: none;
}
nav > ul > li > a:hover {
  color: white;
  text-decoration: underline;
  text-decoration-thickness: 0.2rem;
  text-decoration-color: red;
}