#projects > .project-list > .project-list-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 400px;
  height: 100%;
  margin: auto;
}
#projects > .project-list > .project-list-item > p {
  max-width: 30ch;
}
#projects > .project-list > .project-list-item > a {
  display: flex;
  flex-direction: column;
}

#projects > .project-list > .project-list-item > a > img {
  margin: 1rem auto;
  width: 70%;
  border-radius: 0.5rem;
  box-shadow: 0 0 0.5rem lightgrey;
  max-width: 100%;
}
#projects > .project-list > .project-list-item > footer {
  margin-top: auto;
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
}

#projects > .project-list > .project-list-item > footer > a {
  background-color: lightskyblue;
  color: black;
  padding: 0.8rem;
  border-radius: 0.5rem;
  text-decoration: none;
}
#projects > .project-list > .project-list-item > footer > a:hover {
  opacity: 0.8;
}
