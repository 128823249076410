#featured-project {
  --offset: 4rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  line-height: 1.6rem;
}
#featured-project > h3 {
  font-size: 2.5rem;
}
#featured-project h4 {
  font-size: 1.6rem;
  margin: 1rem 0;
}

#featured-project > .feature-group {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 1rem 0;
}
#featured-project > .feature-group > p {
  max-width: 35ch;
  margin: 1rem auto;
}
#featured-project > .feature-group > .feature-image {
  margin: 0 auto;
  width: 70%;
  max-width: 20rem;
  box-shadow: 0 0 1rem black;
  border-radius: 0.5rem;
}
#featured-project > .feature-group > .feature-collage {
  padding: var(--offset) 0;
  margin: 0 auto;
  border-radius: 0.5rem;
}
#featured-project > .feature-group > .feature-collage > img {
  margin-right: calc(var(--offset) * -0.5);
  border-radius: 0.5rem;
  box-shadow: 0 0 1rem black;
  aspect-ratio: 10/27;
  width: calc((100% / 3) + (var(--offset) / 3));
  max-width: calc((20rem / 3) + (var(--offset) / 3));
}
#featured-project > .feature-group > .feature-collage > img:first-child {
  margin-top: calc(var(--offset) * -1);
  margin-bottom: var(--offset);
}
#featured-project > .feature-group > .feature-collage > img:last-child {
  margin-top: var(--offset);
  margin-bottom: calc(var(--offset) * -1);
}

#featured-project > .feature-full-width {
  text-align: center;
}
#featured-project > .feature-full-width > div {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
#featured-project > .feature-full-width > .feature-links {
  flex-direction: row;
  gap: 0.5rem;
}
#featured-project > .feature-full-width > .feature-links > a {
  background-color: lightskyblue;
  color: black;
  padding: 0.8rem;
  border-radius: 0.5rem;
  text-decoration: none;
}
#featured-project > .feature-full-width > .feature-links > a:hover {
  opacity: 0.8;
}

@media (min-width: 430px) {
  #featured-project > .feature-full-width > div {
    flex-direction: row;
    gap: 0.5rem;
  }
}

@media (min-width: 667px) {
  #featured-project > .feature-group {
    flex-direction: row;
    align-items: center;
  }
  #featured-project > .feature-group:nth-child(odd) {
    flex-direction: row-reverse;
  }
  #featured-project > .feature-group > .feature-image,
  #featured-project > .feature-group > .feature-collage {
    max-width: 30%;
  }
  #featured-project > .feature-group > p {
    margin: 1rem;
    max-width: min(35ch, 50%);
  }
}
