#projects > .project-list {
  display: grid;
  grid-template-columns: 1fr;
  gap: 2rem;
  margin-bottom: 4rem;
}
@media (min-width: 667px) {
  #projects > .project-list {
    grid-template-columns: 1fr 1fr;
  }
}
