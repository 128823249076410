#about {
  display: flex;
  flex-direction: column;
  height: 100%;
}
#about > .about-content {
  line-height: 1.5rem;
  --about-width: 50ch;
  display: flex;
  flex-direction: column;
  align-items: center;
} 

#about > .about-content > img {
  width: min(100%, calc(100vh - 8rem));
  max-width: var(--about-width);
  border-radius: 0.5rem;
}
#about > .about-content > .about-text  {
  max-width: var(--about-width);
}
@media (min-width: 667px) and (max-height: 450px) {
  #about {
    font-size: 1.5rem;
    line-height: 2.0rem;
  }
  #about > .about-content > img {
    margin-top: -2rem;
  }
}